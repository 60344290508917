<div
  class="header-container"
  *ngIf="variantsData.colorVariantArr.length || variantsData.sizeVariantArr.length"
>
  <div>
    <p class="content-secondary-color body2--medium">
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_OPTIONS' | translate }}
    </p>
    <div>
      <section
        *ngIf="variantsData && variantsData.colorVariantArr && variantsData.colorVariantArr.length"
        class="color-variants"
      >
        <button
          *ngFor="let color of variantsData.colorVariantArr; index as idx"
          class="color-variant-button"
          [ngClass]="{ 'selected-color-variant': color === variantsData.selectedColor }"
          (click)="onSelectVariant({ color: color })"
        >
          <p *ngIf="variantsData.colorNamesArray[idx]" class="color-tooltip">
            {{ variantsData.colorNamesArray[idx] }}
          </p>
          <div class="color-variant-background" [ngStyle]="{ backgroundColor: color }"></div>
        </button>
      </section>
      <section
        *ngIf="variantsData && variantsData.sizeVariantArr && variantsData.sizeVariantArr.length"
        class="size-variants"
      >
        <button
          *ngFor="let size of variantsData.sizeVariantArr"
          class="size-variant-button"
          [ngClass]="{ 'selected-size-variant': size === variantsData.selectedSize }"
          (click)="onSelectVariant({ size: size })"
        >
          <p class="content-main-color body2--medium">{{ size }}</p>
        </button>
      </section>
    </div>
  </div>
</div>
