<div class="additionalInfoContainer" #infoSection>
  <div class="tabGroup__additionalInfo">
    <button
      *ngIf="additionalInfo.productDescription"
      class="tabItem tab__productDescription"
      [ngClass]="{ 'tabItem--selected': selectedTab === 1 }"
      (click)="onSelectTab(1)"
    >
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.PRODUCT_DESCRIPTION' | translate }}
    </button>
    <button
      *ngIf="additionalInfo.productSpecifications"
      class="tabItem tab__productSpecifications"
      [ngClass]="{ 'tabItem--selected': selectedTab === 2 }"
      (click)="onSelectTab(2)"
    >
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.PRODUCT_DETAILS' | translate }}
      <span class="body2--medium" *ngIf="keywordsFeatureFlag && productKeywords.length > 0">
        {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.KEYWORDS' | translate }}
      </span>
    </button>
    <button
      *ngIf="additionalInfo.productHowToUse"
      class="tabItem tab__productHowToUse"
      [ngClass]="{ 'tabItem--selected': selectedTab === 3 }"
      (click)="onSelectTab(3)"
    >
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.HOW_TO_USE' | translate }}
    </button>
    <button
      *ngIf="additionalInfo.productEmbeddedVideos.length > 0"
      class="tabItem tab__productEmbeddedVideos"
      [ngClass]="{ 'tabItem--selected': selectedTab === 4 }"
      (click)="onSelectTab(4)"
    >
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.PRODUCT_VIDEOS' | translate }}
    </button>
    <button
      class="tabItem tab__productEmbeddedVideos"
      [ngClass]="{ 'tabItem--selected': selectedTab === 5 }"
      *ngIf="enableProductCheckout && productCheckoutDetail?.active"
      (click)="onSelectTab(5)"
    >
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.CUSTOMIZE_LANDING_PAGE' | translate }}
      <span class="new-status-tag caption2--medium">
        {{ 'CATALOG_PAGE.NEW_TAB' | translate }}
      </span>
    </button>
  </div>
  <div class="displayedAdditionalInfoContainer" id="displayedAdditionalInfoContainer">
    <ul class="productDescription" *ngIf="selectedTab === 1">
      <li
        *ngFor="let productDescriptionItem of productDescriptionList"
        [ngClass]="{ 'specs--bold': productDescriptionItem.type === 'bold' }"
      >
        {{ productDescriptionItem.text }}
      </li>
    </ul>
    <ul class="productSpecifications" *ngIf="selectedTab === 2">
      <div *ngIf="productKeywords.length > 0 && keywordsFeatureFlag">
        <p class="body2--bold content-main">
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.KEYWORDS_HEADLINE' | translate }}
        </p>
        <div *ngIf="loadKeywordsClicked; else notClicked" class="keywords">
          <span *ngFor="let item of productKeywords" class="keywords_chip caption1--bold">
            {{ item }}
            <i
              class="icon icon-copy profile-card__referral-code-icon"
              appHoverStyle
              [cdkCopyToClipboard]="item"
              (click)="onCopyKeywordsClicked(item)"
            ></i>
          </span>
        </div>
        <ng-template #notClicked>
          <div class="keywords">
            <span class="keywords_chip caption1--bold">
              {{ productKeywords[0] }}
              <i
                class="icon icon-copy profile-card__referral-code-icon"
                appHoverStyle
                [cdkCopyToClipboard]="productKeywords[0]"
                (click)="onCopyKeywordsClicked(productKeywords[0])"
              ></i>
            </span>
            <button (click)="onloadKeywordsClicked()" class="caption1--medium keywords_show-all">
              <img src="{{ assetsPath + 'plus.svg' }}" />
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.KEYWORDS_LOAD_ALL' | translate }}
            </button>
          </div>
        </ng-template>
      </div>
      <li
        *ngFor="let productSpecificationItem of productSpecificationList"
        [ngClass]="{ 'specs--bold': productSpecificationItem.type === 'bold' }"
      >
        {{ productSpecificationItem.text }}
      </li>
    </ul>
    <ng-container *ngIf="selectedTab === 3">
      <ng-container *ngFor="let productHowToUseInfoItem of productHowToUseInfoList; index as idx">
        <p class="productHowToUse">
          {{ productHowToUseInfoItem }}
        </p>
        <br *ngIf="idx !== productHowToUseInfoList.length - 1" />
      </ng-container>
    </ng-container>
    <div *ngIf="selectedTab === 4" class="videos-carousel">
      <button
        class="thumbnail-arrow thumbnail-arrow-right clickable"
        *ngIf="productVidArr.length > 1"
        (click)="onClickThumbnailArrow('right')"
        [disabled]="selectedVideoIndex === 0"
      >
        <img src="assets/img/right-chevron.svg" alt="" />
      </button>
      <div
        class="video-container"
        *ngFor="let mediaItem of productVidArr; index as videoIndex"
        [ngClass]="{ 'display-none': videoIndex !== selectedVideoIndex }"
      >
        <video
          *ngIf="productVidTypeArr[videoIndex] === 'vid'"
          controls="true"
          name="Product video"
          [src]="mediaItem"
          type="video/mov"
          preload="metadata"
        ></video>
        <iframe
          *ngIf="productVidTypeArr[videoIndex] === 'youtube'"
          [src]="mediaItem"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
      <button
        class="thumbnail-arrow thumbnail-arrow-left clickable"
        *ngIf="productVidArr.length > 1"
        (click)="onClickThumbnailArrow('left')"
        [disabled]="selectedVideoIndex === productVidArr.length - 1"
      >
        <img src="assets/img/left-chevron.svg" alt="" />
      </button>
    </div>
    <div class="landing-checkout" *ngIf="selectedTab === 5">
      <div class="heading">
        <p class="heading2--bold">
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.CUSTOMIZE_LANDING_HEADING' | translate }}
        </p>
        <button class="action-btn body2--regular ghost-btn" (click)="redirectToProductCheckout()">
          <i class="icon icon-edit"></i>
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.EDIT_BANNER' | translate }}
        </button>
      </div>
      <div class="description">
        <div class="description__pixel-id">
          <p class="body1--bold color-main">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.TIKTOK_PIXEL' | translate }}
          </p>
          <p class="caption1--regular description__pixel-id__subtitle">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.SUB_HEADING' | translate }}
          </p>
          <input
            [(ngModel)]="productCheckoutDetail.pixelId"
            [placeholder]="
              'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.TIKTOK_PIXEL_ID' | translate
            "
          />
          <ul>
            <li class="caption1--regular">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.TIKTOK_AD_MANAGER' | translate }}
            </li>
          </ul>
        </div>

        <div class="description__editor__container" *ngIf="enableLandingCustomDesc">
          <p class="body1--bold color-main">
            {{
              'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.LANDING_PAGE_DESCRIPTION' | translate
            }}
          </p>
          <p class="caption1--regular description__editor__container_subtitle">
            {{
              'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.LANDING_PAGE_DESCRIPTION_SUB'
                | translate
            }}
          </p>
          <quill-editor
            [(ngModel)]="productCheckoutDetail.description"
            class="caption1--medium notranslate description__editor__container_editor"
            [modules]="quillConfiguration"
            [placeholder]="''"
            [styles]="{ 'min-height': '140px', 'max-height': '310px', 'overflow-y': 'auto' }"
          >
          </quill-editor>
        </div>

        <div class="description__form-switcher" *ngIf="enableLandingFormSwitcher">
          <p class="body1--bold color-main">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.FORM_SWITCHER.TITLE' | translate }}
          </p>
          <p class="caption1--regular description__form-switcher_subtitle">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.FORM_SWITCHER.SUBTITLE' | translate }}
          </p>

          <div class="description__form-switcher__card-container">
            <!-- card 1 -->
            <div
              class="description__form-switcher__card-container__card"
              (click)="setFormIsLong(true)"
            >
              <!-- image -->
              <div
                class="description__form-switcher__card-container__card_image-container"
                [ngClass]="{ selected: productCheckoutDetail.isAddressFormLong }"
              >
                <img
                  src="{{ assetsPath + 'long-form.svg' }}"
                  alt="multiple form inputs illustration"
                />
              </div>
              <!-- content -->
              <p class="caption1--bold color-main">
                {{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.FORM_SWITCHER.CARD1_TITLE'
                    | translate
                }}
              </p>
              <p
                class="caption2--regular description__form-switcher__card-container__card_subtitle"
              >
                {{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.FORM_SWITCHER.CARD1_SUBTITLE'
                    | translate
                }}
              </p>
            </div>
            <!-- card 2 -->
            <div
              class="description__form-switcher__card-container__card"
              (click)="setFormIsLong(false)"
            >
              <!-- image -->
              <div
                class="description__form-switcher__card-container__card_image-container"
                [ngClass]="{ selected: !productCheckoutDetail.isAddressFormLong }"
              >
                <img
                  src="{{ assetsPath + 'short-form.svg' }}"
                  alt="multiple form inputs illustration"
                />
              </div>
              <!-- content -->
              <p class="caption1--bold color-main">
                {{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.FORM_SWITCHER.CARD2_TITLE'
                    | translate
                }}
              </p>
              <p
                class="caption2--regular description__form-switcher__card-container__card_subtitle"
              >
                {{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_ADDITIONAL_INFO.FORM_SWITCHER.CARD2_SUBTITLE'
                    | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container" *ngIf="customLandingFormChanged">
        <button class="action-btn ghost-btn body2--bold" (click)="onDiscardChanges()">
          {{ 'PRODUCT_CHECKOUT_STORE.DISCARD' | translate }}
        </button>
        <button class="action-btn primary-btn body2--bold" (click)="saveLandingFormChanges()">
          {{ 'PRODUCT_CHECKOUT_STORE.APPLY' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
