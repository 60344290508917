import { inject, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Analytics } from 'app/core/domain/products/product-detail.model';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { CPARequestModel } from '../../../../../../core/domain/pricing-calculator.model';
import { LogMixpanelEventUseCase } from '../../../../../../core/usecases/analytics/log-mixpanel-event.usecase';
import { CPACalculatorUseCase } from '../../../../../../core/usecases/pricing-calculator/cpa-calculator.usecase';
import { CpaCalculatorSideEffect } from './cpa-calculator.sideEffects';
import { CpaCalculatorViewEvents } from './cpa-calculator.viewEvents';
import { BeforeCalculations, CpaCalculatorViewState } from './cpa-calculator.viewState';

@Injectable({ providedIn: 'root' })
export class CpaCalculatorPresenter extends BasePresenter<
  CpaCalculatorViewState,
  CpaCalculatorViewEvents,
  CpaCalculatorSideEffect
> {
  private _multiTenancyService = inject(MultitenancyService);

  private _cpaCalculatorUseCase = inject(CPACalculatorUseCase);

  private _translateService = inject(TranslateService);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  private productId: string;

  private defaultState: BeforeCalculations;

  protected defaultViewState(): CpaCalculatorViewState {
    this.defaultState = {
      type: 'BeforeCalculations',
      sellingPrice: '',
      deliveryRate: '33',
      minDeliveryRate: '27',
      maxDeliveryRate: '39',
      calculateCpaButtonDisabled: true,
      isLTR: false,
      currency: '',
    };
    return this.defaultState;
  }

  private init(productAnalytics: Analytics): void {
    if (this.viewState.type !== 'BeforeCalculations') {
      return;
    }

    this.add(
      this._translateService.onLangChange.subscribe({
        next: (event: LangChangeEvent) => {
          if (this.viewState.type === 'BeforeCalculations') {
            this.updateViewState({
              ...this.viewState,
              isLTR: event.lang === ENGLISH_LANGUAGE,
            });
          }
        },
      }),
    );

    let deliveryRate: number;
    if (
      productAnalytics?.confirmationRate === undefined ||
      productAnalytics?.deliveryRate === undefined
    ) {
      deliveryRate = 33;
    } else {
      /* eslint-disable */
      deliveryRate = Math.round(
        productAnalytics?.confirmationRate * productAnalytics?.deliveryRate * 100,
      );
      /* eslint-enable */
    }

    this.updateViewState({
      ...this.viewState,
      minDeliveryRate: (deliveryRate - 6).toString(),
      maxDeliveryRate: (deliveryRate + 6).toString(),
      deliveryRate: deliveryRate.toString(),
      currency: this._multiTenancyService.getCurrentCountry().currency.arabicName,
    });
  }

  protected onViewEvent(event: CpaCalculatorViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.productId = event.productId;
        this.init(event.productAnalytics);
        break;
      }
      case 'ClickCalculateCpa': {
        this.calculateCPA(event.sellingPrice, event.netDeliveryRate);
        break;
      }
      case 'ClickCalculateNewCpa': {
        this.updateViewState(this.defaultState);
        break;
      }
      case 'UpdateDeliveryRate': {
        break;
      }
      case 'ClickWatchVideo': {
        this._logMixpanelEventUseCase.execute({ eventName: 'calculate_cpa_video_button_clicked' });
        this.emitSideEffect({ type: 'ShowDialog' });
        break;
      }
    }
  }

  private calculateCPA(sellingPrice: string, netDeliveryRate: string): void {
    this.updateViewState({
      type: 'Loading',
    });

    const calculateCPAModel: CPARequestModel = {
      productId: this.productId,
      price: Number(sellingPrice),
      netDeliveryRate: Number(netDeliveryRate),
    };

    const rateSARtoUSD = 3.75;

    this.add(
      this._cpaCalculatorUseCase.execute(calculateCPAModel).subscribe({
        next: (res) => {
          this.updateViewState({
            type: 'AfterCalculations',
            cpa: (res.cpa / rateSARtoUSD).toPrecision(4).toString(),
            isLTR: false,
            netDeliveryRate: res.netDeliveryRate.toString(),
            netProfit: res.netProfit.toString(),
            sellingPrice: res.price.toString(),
            currency: this._multiTenancyService.getCurrentCountry().currency.arabicName,
            isHighCpa: res.priceHint === 'HIGH',
          });

          this._logMixpanelEventUseCase.execute({
            eventName: 'calculate_cpa_clicked_success',
            payload: {
              productId: this.productId,
              price: res.price,
              netProfit: res.netProfit,
              deliveryRate: res.netDeliveryRate,
              cpa: res.cpa,
            },
          });
          if (Number(this.defaultState.deliveryRate) !== calculateCPAModel.netDeliveryRate) {
            this._logMixpanelEventUseCase.execute({
              eventName: 'calculate_cpa_DR_changed',
              payload: { deliveryRate: res.netDeliveryRate },
            });
          }
        },
        error: (err) => {
          this.updateViewState(this.defaultState);
          this.emitSideEffect({
            type: 'ShowCalculationError',
            error: 'ERRORS.GENERIC_ERROR_MESSAGE',
          });
          this._logMixpanelEventUseCase.execute({
            eventName: 'calculate_cpa_clicked_error',
            payload: { error: err.error.message },
          });
        },
      }),
    );
  }
}
