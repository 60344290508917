import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProductVariantsData } from 'src/app/core/domain/products/product-detail.model';

@Component({
  selector: 'app-product-variants',
  standalone: true,
  imports: [TranslateModule, NgIf, NgClass, NgFor, NgStyle],
  templateUrl: './product-variants.component.html',
  styleUrls: ['./product-variants.component.scss'],
})
export class ProductVariantsComponent {
  @Output() variantSelected: EventEmitter<{ color?: string; size?: string }> = new EventEmitter();

  @Input() variantsData: ProductVariantsData;

  public selectedColor: string;

  public selectedSize: string;

  onSelectVariant(variantSelected: { color?: string; size?: string }): void {
    const { color, size } = variantSelected;
    if (color) {
      this.variantSelected.emit({ color });
    } else if (size) {
      this.variantSelected.emit({ size });
    }
  }
}
