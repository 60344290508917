<div class="cpa-accordion-container">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <img class="accordion-icon" src="{{ assetsPath + 'calc.svg' }}" />
          <p class="caption1--bold content-main-color">
            {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.HEADER') }}
          </p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="header-container">
        <div>
          <form [formGroup]="cpaCalculatorForm">
            <div class="cpa-container" *ngIf="viewState.type === 'BeforeCalculations'">
              <div class="cpa-container__body">
                <p class="cpa-container__body__number caption1--medium">1</p>
                <p>{{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.SELL_PRICE') }}</p>
                <div class="input-container">
                  <input
                    class="cpa-container__body__input"
                    type="number"
                    formControlName="productPrice"
                    [ngClass]="{
                      'red-border': cpaCalculatorForm.invalid && cpaCalculatorForm.touched
                    }"
                  />
                  <p class="cpa-container__body__input__currency caption1--medium">
                    {{ viewState.currency | currencyTranslate | translate | currencyShortName }}
                  </p>
                </div>
                <div
                  class="cpa-container__body__recommendation"
                  *ngIf="basicInfo.productPriceRange"
                >
                  <img src="{{ assetsPath + 'info.svg' }}" />
                  <p class="caption1--medium content-info-color">
                    {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.PRICE_RECOMMENDATION') }}
                  </p>
                  <p class="body2--bold content-info-color">
                    {{ basicInfo.productPriceRange?.min || basicInfo.productPrice }}
                    <span class="body2--regular content-medium-color">{{
                      trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.TO')
                    }}</span>
                    {{ basicInfo.productPriceRange?.max || basicInfo.productPrice }}
                    {{ viewState.currency | currencyTranslate | translate | currencyShortName }}
                  </p>
                </div>
              </div>
              <div class="separator"></div>
              <div class="cpa-container__body">
                <p class="cpa-container__body__number caption1--medium">2</p>
                <p class="text-width">
                  {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.PRODUCT_DR') }}
                </p>
                <div class="cpa-container__body__slider-container">
                  <mat-slider
                    [ngClass]="{ 'slider-direction': viewState.isLTR }"
                    [min]="viewState.minDeliveryRate"
                    [max]="viewState.maxDeliveryRate"
                    [step]="sliderStep"
                    (input)="
                      presenter.emitViewEvent({
                        type: 'UpdateDeliveryRate',
                        value: $event.value.toString()
                      })
                    "
                    [(ngModel)]="viewState.deliveryRate"
                    [ngModelOptions]="{ standalone: true }"
                    [displayWith]="labelFn"
                    thumbLabel
                  >
                  </mat-slider>
                  <div class="cpa-container__body__slider-container__left-stick"></div>
                  <div class="cpa-container__body__slider-container__right-stick"></div>
                  <div
                    [ngClass]="{ 'rtl-direction': !viewState.isLTR }"
                    class="cpa-container__body__slider-container__values"
                  >
                    <p class="body2--regular content-medium-color">
                      {{ viewState.minDeliveryRate }}%
                    </p>
                    <p class="body2--regular content-medium-color">
                      {{ viewState.maxDeliveryRate }}%
                    </p>
                  </div>
                </div>
              </div>
              <div class="cpa-container__buttons">
                <button
                  class="cpa-container__buttons__CPA cpa-button caption1--medium"
                  (click)="
                    presenter.emitViewEvent({
                      type: 'ClickCalculateCpa',
                      netDeliveryRate: viewState.deliveryRate,
                      sellingPrice: cpaCalculatorForm.value.productPrice!
                    })
                  "
                  [disabled]="cpaCalculatorForm.invalid"
                >
                  {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.CALCULATOR_BTN') }}
                </button>
                <button
                  class="cpa-container__buttons__video cpa-button caption1--medium"
                  (click)="presenter.emitViewEvent({ type: 'ClickWatchVideo' })"
                >
                  <img src="{{ assetsPath + 'video-icon.svg' }}" />
                  {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.VIDEO_BTN') }}
                </button>
              </div>
            </div>
          </form>
          <loader
            size="md"
            *ngIf="viewState.type === 'Loading'"
            [loading]="true"
            class="loader"
          ></loader>
          <div class="cpa-result-container" *ngIf="viewState.type === 'AfterCalculations'">
            <div class="cpa-result">
              <div class="same-row">
                <p class="body2--medium content-medium-color">
                  {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.SELL_PRICE') }}:
                  <span class="body2--medium content-main-color right-space"
                    >{{ viewState.sellingPrice
                    }}<span class="body2--medium content-main-color right-space">{{
                      viewState.currency | currencyTranslate | translate | currencyShortName
                    }}</span></span
                  >
                </p>
                <p class="body2--medium content-medium-color">
                  {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.NET_PROFIT') }}:
                  <span class="body2--medium content-main-color right-space"
                    >{{ viewState.netProfit
                    }}<span class="body2--medium content-main-color right-space">{{
                      viewState.currency | currencyTranslate | translate | currencyShortName
                    }}</span></span
                  >
                </p>
                <p class="body2--medium content-medium-color">
                  {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.PRODUCT_DR') }}:
                  <span class="body2--medium content-main-color right-space"
                    >{{ viewState.netDeliveryRate }}%</span
                  >
                </p>
              </div>
              <div class="same-row">
                <p class="body2--medium content-medium-color">
                  {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.YOUR_CPA') }}:
                  <span class="body2--medium content-main-color right-space"
                    >{{ viewState.cpa }}
                    <span class="body2--medium content-main-color right-space">{{
                      trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.USD')
                    }}</span></span
                  >
                </p>
              </div>
              <div class="cpa-result__high-cpa same-row" *ngIf="viewState.isHighCpa">
                <img src="{{ assetsPath + 'warning.svg' }}" />
                <p class="body2--medium">
                  {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.WARNING') }}
                </p>
              </div>
            </div>
            <button
              class="cpa-container__buttons__video cpa-button caption1--medium"
              (click)="presenter.emitViewEvent({ type: 'ClickCalculateNewCpa' })"
            >
              {{ trans('PRODUCTS_DETAIL_PAGE.CPA_CALCULATOR.NEW_OPERATION') }}
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
