<div class="header-container">
  <div>
    <p class="content-secondary-color body2--medium text-start">
      {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_PRICE_PROFIT') }}
    </p>
    <section class="quantity-price-offer">
      <div class="quantity-container">
        <div class="quantity-info-container">
          <div class="same-row">
            <div class="title-container">
              <div class="title-info">
                <img [src]="assetsPath + 'price-tag.svg'" alt="price-tag" />
                <p class="caption1--bold">
                  {{
                    'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_QUANTITY_DISCOUNT.TITLE'
                      | translate
                  }}
                </p>
              </div>
              <p
                *ngIf="numberOfItems < 2; else quantityDiscountApplied"
                class="quantity-info caption2--medium"
              >
                {{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_QUANTITY_DISCOUNT.INFO'
                    | translate
                }}
              </p>
              <ng-template #quantityDiscountApplied>
                <p class="discount-info caption2--medium">
                  {{
                    'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_QUANTITY_DISCOUNT.DISCOUNT_APPLIED'
                      | translate
                  }}
                </p>
              </ng-template>
            </div>
            <div class="number-of-items-container">
              <button
                class="items-count-change-button clickable"
                (click)="onChangeNoOfItems('decrement')"
                [ngClass]="{ dimmed: numberOfItems === 1 }"
              >
                -
              </button>
              <p class="number-of-items body1--bold">
                {{ numberOfItems }}
              </p>
              <button
                class="items-count-change-button clickable"
                (click)="onChangeNoOfItems('increment')"
              >
                +
              </button>
            </div>
          </div>
          <div class="price-info">
            <div class="row">
              <div class="col-4 caption1--medium">
                {{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_QUANTITY_DISCOUNT.PRICE_PER_PIECE'
                    | translate
                }}
              </div>
              <div class="col-4 caption1--medium">
                {{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_QUANTITY_DISCOUNT.PRICE_PER_QUANTITY'
                    | translate
                }}
              </div>
              <div class="col-4 caption1--medium">
                {{
                  'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_QUANTITY_DISCOUNT.TOTAL_PROFIT'
                    | translate
                }}
              </div>
            </div>
            <div class="row value-row">
              <div class="col-4" [ngClass]="{ 'single-price-update': numberOfItems > 1 }">
                <span class="body1--bold">{{
                  math.round(
                    (productPrice + (productPrice - quantityDiscountValue) * (numberOfItems - 1)) /
                      numberOfItems
                  )
                }}</span>
                <span class="currency body1--bold">
                  {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</span
                >
                <p *ngIf="saleInfo?.productPrice && numberOfItems < 2" class="sale-price-striked">
                  <s
                    >{{ basicInfo.productPrice }}
                    {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</s
                  >
                </p>
              </div>
              <div class="col-4">
                <span class="body1--bold" *ngIf="numberOfItems < 2; else quantityDiscount">{{
                  productPrice * numberOfItems
                }}</span>
                <ng-template #quantityDiscount>
                  <span class="body1--bold">{{
                    productPrice + (productPrice - quantityDiscountValue) * (numberOfItems - 1)
                  }}</span>
                </ng-template>
                <span class="currency body1--bold">
                  {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</span
                >
              </div>
              <div class="col-4 profit">
                <span class="body1--bold">{{ productProfit * numberOfItems }}</span>
                <span class="currency body1--bold">
                  {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</span
                >
                <p
                  *ngIf="saleInfo?.productProfit && numberOfItems < 2"
                  class="sale-price-striked body1--bold"
                >
                  <s class="body1--bold"
                    >{{ basicInfo.productProfit }}
                    {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</s
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="max-price-container"
        *ngIf="shouldShowPricingGovernance && basicInfo.productMaxPrice"
      >
        <div class="maximum-price">
          <img src="assets/img/trend-up.svg" />
          <p class="title caption1--bold">
            {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.MAXIMUM_PRICE_TITLE') }}
          </p>
          <p class="body2--bold price-info">
            {{ basicInfo.productMaxPrice }}
            <span class="currency">
              {{ basicInfo.currency | currencyTranslate | translate | currencyShortName }}</span
            >
          </p>
        </div>
        <app-information-notice
          [text]="'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_MAXIMUM_PRICE_INFO'"
        />
      </div>
    </section>
  </div>
</div>
