import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { PRODUCT_CHECKOUT_URL } from 'app/presentation/shared/constants';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { QuillModule } from 'ngx-quill';
import { ProductAdditionalInfo } from 'src/app/core/domain/products/product-detail.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import {
  SHOW_PRODUCT_KEYWORDS,
  WEB_CUSTOM_CHECKOUT,
  WEB_CUSTOM_LANDING_DESC,
  WEB_CUSTOM_LANDING_FORM_SWITCHER,
} from 'src/app/presentation/shared/constants/feature-flags';
// eslint-disable-next-line prettier/prettier
import { country } from '@/v3/features/country/data/services/country.service';
// eslint-disable-next-line
import { user } from '@/v3/features/user/data/services/user';
// eslint-disable-next-line import/extensions
import 'quill-emoji/dist/quill-emoji.js';
// eslint-disable-next-line
import { ProductCheckoutModel } from 'app/core/domain/products/product-checkout';

@Component({
  selector: 'app-product-additional-info',
  templateUrl: './product-details-additional-info.component.html',
  styleUrls: ['./product-details-additional-info.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, TranslateModule, ClipboardModule, FormsModule, QuillModule],
})
export class ProductDetailsAdditionalInfoComponent implements OnInit, OnChanges {
  @Input() additionalInfo: ProductAdditionalInfo;

  @Input() prodId: string;

  @Input() productCheckoutDetail: ProductCheckoutModel;

  @Output() changeDisplayedAdditionalInfo: EventEmitter<string> = new EventEmitter();

  @Output() checkoutProductPixelID: EventEmitter<null | string> = new EventEmitter();

  @Output() checkoutProductFormData: EventEmitter<null | {
    pixelID?: string;
    isAddressFormLong?: boolean;
    description?: string;
  }> = new EventEmitter();

  @ViewChild('infoSection') infoSectionEl: ElementRef<HTMLElement>;

  enableProductCheckout = false;

  selectedTab = 1;

  selectedVideoIndex = 0;

  productSpecificationList: any = [];

  productDescriptionList: any = [];

  isProductDescriptionStyleBulletPoints: boolean;

  productHowToUseInfoList: any = [];

  productVidArr: any[] = [];

  productVidTypeArr: string[] = [];

  productKeywords: string[] = [];

  keywordsFeatureFlag: boolean;

  loadKeywordsClicked = false;

  enableLandingCustomDesc = false;

  enableLandingFormSwitcher = false;

  originalForm: { pixelId?: string; isAddressFormLong?: boolean; description?: string } = {};

  public quillConfiguration = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ['emoji'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ],
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
  };

  public assetsPath = '../../../../../assets/img/';

  constructor(
    private sanitizer: DomSanitizer,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _router: Router,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {}

  ngOnInit(): void {
    this._shouldShowProductCheckout();
    this._getFeatureFlagUseCase.execute(SHOW_PRODUCT_KEYWORDS).subscribe((flag) => {
      this.keywordsFeatureFlag = flag;
    });

    this._getFeatureAttributeUseCase.execute(WEB_CUSTOM_LANDING_DESC).subscribe({
      next: (attribute) => {
        this.enableLandingCustomDesc = featureAttributeAssign(attribute, user.id, country.code);
      },
    });

    this._getFeatureAttributeUseCase.execute(WEB_CUSTOM_LANDING_FORM_SWITCHER).subscribe({
      next: (attribute) => {
        this.enableLandingFormSwitcher = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  private _shouldShowProductCheckout(): void {
    this._getFeatureAttributeUseCase.execute(WEB_CUSTOM_CHECKOUT).subscribe({
      next: (attribute) => {
        this.enableProductCheckout = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  ngOnChanges(): void {
    this.formatProductSpecificationsList();
    this.formatProductDescriptionList();
    this.formatproductHowToUseInfoList();
    this.selectedTab = 1;
    this.productVidArr = [];
    this.productVidTypeArr = [];
    this.additionalInfo.productEmbeddedVideos.forEach((vid) => {
      this.addVideos(vid);
    });
    this.productKeywords = this.additionalInfo.productKeywords;
    if (this.enableProductCheckout && this.productCheckoutDetail) {
      this.originalForm = {
        pixelId: this.productCheckoutDetail?.pixelId,
        isAddressFormLong: this.productCheckoutDetail?.isAddressFormLong,
        description: this.productCheckoutDetail?.description,
      };
    }
  }

  get customLandingFormChanged(): boolean {
    return (
      this.productCheckoutDetail.pixelId !== this.originalForm.pixelId ||
      this.productCheckoutDetail.isAddressFormLong !== this.originalForm.isAddressFormLong ||
      this.productCheckoutDetail.description !== this.originalForm.description
    );
  }

  setVideoDimesnions(): void {
    const displayedAdditionalInfoContainerElement = document.getElementById(
      'displayedAdditionalInfoContainer',
    );
    if (this.selectedTab === 4 && displayedAdditionalInfoContainerElement) {
      const width = displayedAdditionalInfoContainerElement.offsetWidth;
      displayedAdditionalInfoContainerElement.style.height = `${width * 0.5625}px`;
    } else {
      displayedAdditionalInfoContainerElement!.style.height = '';
    }
  }

  onSelectTab(id: number): void {
    this.selectedTab = id;
    this.setVideoDimesnions();
    let tabName = 'product description';
    switch (this.selectedTab) {
      case 1:
        tabName = 'product description';
        break;
      case 2:
        tabName = 'product specification';
        break;
      case 3:
        tabName = 'product how to use';
        break;
      case 4:
        tabName = 'product videos';
        break;
      case 5:
        tabName = 'Pixel';
        break;
    }
    this.changeDisplayedAdditionalInfo.emit(tabName);
  }

  formatProductSpecificationsList(): void {
    const boldPattern = /^\*.+\* *$/;
    const starRegex = /\*/gi;
    if (this.additionalInfo.productSpecifications) {
      this.productSpecificationList = this.additionalInfo.productSpecifications
        .replace(/•\s?/g, '')
        .split('\r\n')
        .filter((spec) => spec !== '');
    }
    this.productSpecificationList = this.productSpecificationList.map((specification: any) => {
      if (boldPattern.test(specification)) {
        return {
          text: specification.replace(starRegex, ''),
          type: 'bold',
        };
      }
      return { text: specification, type: 'none' };
    });
  }

  formatProductDescriptionList(): void {
    const boldPattern = /^\*.+\* *$/;
    const starRegex = /\*/gi;
    if (this.additionalInfo.productDescription) {
      this.productDescriptionList = this.additionalInfo.productDescription
        .replace(/•\s?/g, '')
        .split('\r\n')
        .filter((spec) => spec !== '');
    }
    this.productDescriptionList = this.productDescriptionList.map((description: any) => {
      if (boldPattern.test(description)) {
        return {
          text: description.replace(starRegex, ''),
          type: 'bold',
        };
      }
      return { text: description, type: 'none' };
    });
  }

  formatproductHowToUseInfoList(): void {
    this.productHowToUseInfoList = this.additionalInfo.productHowToUse?.split(/\r?\n/);
  }

  getVideoType(link: string): string {
    switch (link.substr(link.lastIndexOf('.') + 1).toLowerCase()) {
      case 'mp4':
      case 'mov':
        return 'vid';
      default:
        return 'youtube';
    }
  }

  addVideos(video: string): void {
    const videoType = this.getVideoType(video);
    if (videoType === 'youtube') {
      this.productVidArr.push(this.sanitizer.bypassSecurityTrustResourceUrl(video));
      this.productVidTypeArr.push(videoType);
    } else {
      this.productVidArr.push(video);
      this.productVidTypeArr.push(videoType);
    }
  }

  onClickThumbnailArrow(direction: string): void {
    if (direction === 'right' && this.selectedVideoIndex > 0) {
      this.selectedVideoIndex--;
    } else if (direction === 'left' && this.selectedVideoIndex < this.productVidArr.length - 1) {
      this.selectedVideoIndex++;
    }
  }

  onloadKeywordsClicked(): void {
    this.loadKeywordsClicked = true;
    this._logMixpanelEventUseCase.execute({
      eventName: 'product_details_show_more_keywords_clicked',
      payload: {
        product_id: this.prodId,
      },
    });
  }

  onCopyKeywordsClicked(keyword: string): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'product_details_copy_keyword_clicked',
      payload: {
        product_id: this.prodId,
        keyword,
      },
    });
  }

  redirectToProductCheckout(): void {
    this._router.navigate([PRODUCT_CHECKOUT_URL]);
  }

  savePixelID(): void {
    this.checkoutProductPixelID.emit(this.productCheckoutDetail.pixelId);
  }

  onDiscardChanges(): void {
    this.productCheckoutDetail.pixelId = this.originalForm.pixelId;
    this.productCheckoutDetail.isAddressFormLong = this.originalForm.isAddressFormLong;
    this.productCheckoutDetail.description = this.originalForm.description;
  }

  saveLandingFormChanges(): void {
    if (this.enableLandingFormSwitcher) {
      const formData = {
        pixelId: this.productCheckoutDetail.pixelId,
        isAddressFormLong: this.productCheckoutDetail.isAddressFormLong,
        description: this.productCheckoutDetail.description,
      };

      this.checkoutProductFormData.emit(formData);
    } else {
      // Temporary implementation of the pixel id without the rest of form values
      this.checkoutProductPixelID.emit(this.productCheckoutDetail.pixelId);
    }

    window.scrollTo({
      top: this.infoSectionEl.nativeElement.getBoundingClientRect().top + window.scrollY - 80,
      behavior: 'smooth',
    });
  }

  setFormIsLong(isLong: boolean): void {
    this.productCheckoutDetail.isAddressFormLong = isLong;
  }
}
