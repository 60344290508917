import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cpa-video-component',
  standalone: true,
  templateUrl: './cpa-video-component.component.html',
  styleUrls: ['./cpa-video-component.component.scss'],
})
export class CpaVideoComponentComponent {
  private _dialog = inject(MatDialog);

  closeVideoDialog(): void {
    this._dialog.closeAll();
  }
}
