import { DecimalPipe, NgClass, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { toastError } from '@presentation/shared/toast';

import { Analytics, ProductBasicInfo } from 'app/core/domain/products/product-detail.model';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { LoaderComponent } from 'src/app/presentation/shared/components/loader/loader.component';
import { CurrencyShortNamePipe } from 'src/app/presentation/shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from 'src/app/presentation/shared/pipes/currency-translate.pipe';
import { CpaCalculatorPresenter } from './cpa-calculator-presenter/cpa-calculator.presenter';
import { CpaCalculatorSideEffect } from './cpa-calculator-presenter/cpa-calculator.sideEffects';
import { CpaCalculatorViewEvents } from './cpa-calculator-presenter/cpa-calculator.viewEvents';
import { CpaCalculatorViewState } from './cpa-calculator-presenter/cpa-calculator.viewState';
import { CpaVideoComponentComponent } from './cpa-video-component/cpa-video-component.component';

@Component({
  selector: 'app-cpa-calculator',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    TranslateModule,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    MatSliderModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    LoaderComponent,
    DecimalPipe,
    MatExpansionModule,
    MatIconModule,
  ],
  templateUrl: './cpa-calculator.component.html',
  styleUrls: ['./cpa-calculator.component.scss'],
  providers: [CpaCalculatorPresenter],
})
export class CpaCalculatorComponent extends BaseComponent<
  CpaCalculatorPresenter,
  CpaCalculatorViewState,
  CpaCalculatorViewEvents,
  CpaCalculatorSideEffect
> {
  @Input() basicInfo: ProductBasicInfo;

  @Input() productObjectId: string;

  @Input() productAnalytics: Analytics;

  public assetsPath = 'assets/img/product-details-icons/';

  public sliderStep = 1;

  public cpaCalculatorForm = new FormGroup({
    productPrice: new FormControl('', [Validators.required, Validators.min(0)]),
  });

  private _translateService = inject(TranslateService);

  private _dialog = inject(MatDialog);

  public presenter: CpaCalculatorPresenter = inject(CpaCalculatorPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
      productAnalytics: this.productAnalytics,
      productId: this.productObjectId,
    });
  }

  labelFn(value: number): string {
    return `${value}%`;
  }

  onSideEffect(sideEffect: CpaCalculatorSideEffect): void {
    switch (sideEffect.type) {
      case 'ShowCalculationError': {
        toastError(this._translateService.instant(sideEffect.error));
        break;
      }
      case 'ShowDialog': {
        this._dialog.open(CpaVideoComponentComponent, { width: '800px' });
        break;
      }
    }
  }
}
