import { NgClass, NgIf, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { InformationNoticeComponent } from 'app/presentation/shared/components/information-notice/information-notice.component';
import { WEB_PRICE_GOVERNANCE } from 'app/presentation/shared/constants/feature-flags';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import {
  ProductAvailability,
  ProductBasicInfo,
  Variant,
} from 'src/app/core/domain/products/product-detail.model';
import { VariantSalesModel } from 'src/app/core/domain/variant-group.model';
import { QUANTITY_DISCOUNT_VALUE } from 'src/app/presentation/shared/constants/quantity-discount';
import { MixpanelService } from 'src/app/presentation/shared/services/mixpanel.service';
import { CurrencyShortNamePipe } from '../../../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../../../shared/pipes/currency-translate.pipe';
// eslint-disable-next-line
import { user } from '@/v3/features/user/data/services/user';

@Component({
  selector: 'app-product-quantity-offers',
  templateUrl: './product-quantity-offers.component.html',
  styleUrls: ['./product-quantity-offers.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    NgClass,
    TranslateModule,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
    UpperCasePipe,
    InformationNoticeComponent,
  ],
})
export class ProductQuantityOffersComponent extends LocalizedComponent implements OnInit {
  constructor(
    private _mixpanelService: MixpanelService,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {
    super();
  }

  @Input() basicInfo: ProductBasicInfo;

  @Input() saleInfo: VariantSalesModel;

  @Input() productAvailability: ProductAvailability;

  @Input() productIsOrderable: boolean;

  @Input() currentProduct: Variant;

  numberOfItems: number;

  productPrice: number;

  productProfit: number;

  productAvailabilityEnum = ProductAvailability;

  math = Math;

  public quantityDiscountValue: number;

  public assetsPath = 'assets/img/';

  public shouldShowPricingGovernance = false;

  ngOnInit(): void {
    this.enablePricingGovernance();
    this.setProductDetails();
  }

  private enablePricingGovernance(): void {
    this._getFeatureAttributeUseCase.execute(WEB_PRICE_GOVERNANCE).subscribe({
      next: (attribute) => {
        this.shouldShowPricingGovernance = featureAttributeAssign(attribute, user.id);
      },
    });
  }

  public setProductDetails(): void {
    this.productPrice = this.saleInfo?.productPrice || this.basicInfo.productPrice;
    this.productProfit = this.saleInfo?.productProfit || this.basicInfo.productProfit;
    if (this.currentProduct.discountForAdditionalQuantity) {
      this.quantityDiscountValue = this.currentProduct.discountForAdditionalQuantity.amount;
    } else {
      this.quantityDiscountValue = QUANTITY_DISCOUNT_VALUE;
    }
  }

  ngOnChanges(): void {
    this.numberOfItems = 1;
  }

  onChangeNoOfItems(operation: string): void {
    if (operation === 'increment') {
      this.numberOfItems++;
    } else if (operation === 'decrement' && this.numberOfItems > 1) {
      this.numberOfItems--;
    }
    this._mixpanelService.track('priceCalculator used', {
      operation,
      quantity: this.numberOfItems,
    });
  }
}
